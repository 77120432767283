import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import EditableTabs from "../Components/editableTab";
import { useParams } from "react-router-dom";
const Main = () => {
  const [details, setDetails] = useState({});
  const [currentId, setCurrentId] = useState("");
  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  const { groupId } = useParams();
  useEffect(() => {
    console.log(localStorage.getItem("objs")[groupId]);
    setDetails(JSON.parse(localStorage.getItem("objs"))[groupId]);
  }, [groupId]);
  useEffect(() => {
    let obj = JSON.parse(localStorage.getItem("objs"));
    if (obj === null) {
      obj = {};
    }
    obj[groupId] = details;
    localStorage.setItem("objs", JSON.stringify(obj));
  }, [details, groupId]);
  const CopyText = (text) => {
    if (!open) {
      navigator.clipboard.writeText(text);
    }
  };
  const setLocalStorage = () => {
    let obj = JSON.parse(localStorage.getItem("objs"));
    obj[groupId] = details;
    localStorage.setItem("objs", JSON.stringify(obj));
  };
  const reset = () => {
    setName("");
    setValue("");
    setOpen(false);
    setCurrentId("");
  };
  return (
    <div className="Main">
      <div className="container-bg d-xl-flex p-3 flex-wrap justify-content-start">
        {Object.keys(details).map((feild, index) => (
          <>
            <div
              key={index}
              className=" m-1 border btn btn-light p-5"
              onClick={() => {
                CopyText(details[feild]?.value);
              }}
              onDoubleClick={(e) => {
                setCurrentId(e.target.id);
                setOpen(true);
              }}
              id={feild}
            >
              {currentId === feild && open ? (
                <div class="p-2">
                  <EditableTabs
                    feilds={[
                      {
                        name: "name",
                        text: "Name",
                        value: name,
                        setValue: setName,
                      },
                      {
                        name: "value",
                        text: "Value",
                        value: value,
                        setValue: setValue,
                      },
                    ]}
                  />
                  <button
                    onClick={() => {
                      let obj = details;
                      delete obj[currentId];
                      setDetails(obj);
                      setLocalStorage();
                      reset();
                    }}
                    className=" m-1 border btn btn-danger"
                  >
                    Delete
                  </button>
                  <button
                    onClick={() => {
                      setDetails({
                        ...details,
                        ...{ [currentId]: { name, value } },
                      });
                      reset();
                    }}
                    className=" m-1 border btn btn-primary"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => {
                      reset();
                    }}
                    className=" m-1 border btn btn-dark"
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                // <ul class="list-group list-group-horizontal">
                //   <li class="list-group-item">An item</li>
                //   <li class="list-group-item">{details[feild]?.name}</li>
                // </ul>
                details[feild]?.name
              )}
            </div>
          </>
        ))}
        <button
          className="p-5 m-1 border btn btn-outline-secondary"
          onClick={() => {
            const id = uuid();
            setDetails({
              ...details,
              ...{ [id]: { name: "foo", value: "bar" } },
            });
            setCurrentId(id);
          }}
        >
          +
        </button>
      </div>
      {!open ? (
        <div class="position-absolute bottom-0 start-50 translate-middle-x border-top px-5 py-1 gayab">
          Double Click to edit .... Click to Copy
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Main;
