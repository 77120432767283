import React from "react";

function EditableTabs(props) {
  const { feilds } = props;
  return (
    <div>
      {feilds.map((feild) => (
        <div class="input-group mb-3">
          <div class="input-group-text" id="basic-addon1">
            {feild.text}
          </div>
          <input
            value={feild.value}
            onChange={(e) => feild.setValue(e.target.value)}
            type="text"
            class="form-control"
            placeholder="Value"
            aria-label="Value"
            aria-describedby="basic-addon1"
          />
        </div>
      ))}
    </div>
  );
}

export default EditableTabs;
