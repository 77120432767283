import React, { useEffect, useState } from "react";
import EditableTabs from "../Components/editableTab";
import { useNavigate } from "react-router-dom";
import { AiFillEdit } from "react-icons/ai";

const Main = () => {
  const [details, setDetails] = useState({});
  const [currentId, setCurrentId] = useState("");
  const [name, setName] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    let obj = JSON.parse(localStorage.getItem("objs"));
    setDetails(obj);
  }, []);

  useEffect(() => {
    localStorage.setItem("objs", JSON.stringify(details));
  }, [details]);

  const setLoacalStorage = () => {
    localStorage.setItem("objs", JSON.stringify(details));
  };

  const reset = () => {
    setName("");
    setOpen(false);
    setCurrentId("");
  };

  return (
    <div className="Main">
      <div className="container">
        <h1>Groups</h1>
      </div>
      <div className="container-bg d-xl-flex p-3 flex-wrap justify-content-start">
        {Object.keys(details).map((feild, index) => (
          <>
            <div
              className="btn-group m-1 border border-2 "
              role="group"
              aria-label="Basic example"
              key={index}
              id={feild}
            >
              {currentId === feild && open ? (
                <div class="p-2">
                  <EditableTabs
                    feilds={[
                      {
                        name: "name",
                        text: "Name",
                        value: name,
                        setValue: setName,
                      },
                    ]}
                  />
                  <button
                    onClick={() => {
                      const obj = details;
                      delete obj[currentId];
                      setDetails(obj);
                      setLoacalStorage();
                      reset();
                    }}
                    className=" m-1 border btn btn-danger"
                  >
                    Delete
                  </button>
                  <button
                    onClick={() => {
                      let obj = details;
                      obj[name] = obj[currentId];
                      delete obj[currentId];
                      setDetails(obj);
                      setLoacalStorage();
                      reset();
                    }}
                    className=" m-1 border btn btn-primary"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => {
                      reset();
                    }}
                    className=" m-1 border btn btn-dark"
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <>
                  <button
                    onClick={(e) => {
                      console.log(e.target.id);
                      navigate(`/group/${e.target.id}`);
                    }}
                    type="button"
                    class="btn btn-light"
                    id={feild}
                  >
                    {feild}
                  </button>
                  <button
                    onClick={(e) => {
                      console.log("edit");
                      setCurrentId(e.target.id);
                      setOpen(true);
                    }}
                    type="button"
                    class="btn btn-secondary"
                    id={feild}
                  >
                    <AiFillEdit />
                  </button>
                </>
              )}
            </div>
          </>
        ))}
        <button
          className="btn-group m-1 border border-2 py-2 px-3"
          onClick={() => {
            const id = `YourGroup${Math.floor(Math.random() * 101)}`;
            setDetails({
              ...details,
              ...{ [id]: {} },
            });
            setLoacalStorage();
            setCurrentId(id);
          }}
        >
          +
        </button>
      </div>
    </div>
  );
};

export default Main;
