import { Link } from "react-router-dom";
export default function Navbar() {
  const handleImport = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      localStorage.setItem("objs", e.target.result);
    };
    reader.readAsText(file);
  };
  const handleExport = () => {
    let json = localStorage.getItem("objs");
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);
    // create "a" HTLM element with href to file
    const link = document.createElement("a");
    link.href = href;
    link.download = "EzzCopy" + ".json";
    document.body.appendChild(link);
    link.click();
    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };
  return (
    <div class="sticky-sm-top">
      <nav class="navbar  navbar-expand-lg bg-dark">
        <div class="container-fluid">
          <Link class="navbar-brand mx-4 text-white" to="/">
            Ezz Copy
          </Link>
        </div>
        <div class="d-flex wrapperss">
          <button
            type="button"
            onClick={() => handleExport()}
            class="btn btn-secondary m-1"
          >
            Export
          </button>
          <input
            id="fileInput"
            type="file"
            accept=".json"
            multiple={false}
            style={{ display: "none" }}
            onChange={(e) => handleImport(e)}
          />
          <button
            type="button"
            onClick={() => document.getElementById("fileInput").click()}
            class="btn btn-secondary m-1 me-4"
          >
            Import
          </button>
        </div>
      </nav>
    </div>
  );
}
