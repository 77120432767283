import { Routes, Route } from "react-router-dom";
import Main from "../Pages/value.js";
import Group from "../Pages/groupCopy";
import Navbar from "../Components/navbar.js";
function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route index element={<Group />} />
        <Route path="groups" element={<Group />} />
        <Route path="group/:groupId" element={<Main />} />
      </Routes>
    </div>
  );
}

export default App;
